<template>
  <v-container>
    <v-row justify="center" class="text-h5 font-weight-medium mb-5">
      Em que podemos ajudar?
    </v-row>
    <v-row ref="search" justify="center" class="mb-2 mx-5">
      <v-col>
        <div class="gcse-search" style="width: 90%" @click="googleSearch"></div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
      :class="breakpoint ? 'flex-column' : 'align-stretch'"
    >
      <v-col v-for="card in cards" :key="card.key" v-show="card.show">
        <v-card
          style="margin: auto"
          height="275"
          min-width="275"
          max-width="350"
          :class="`pt-6 pb-3 card ${card.action.class}`"
          outlined
          hover
          @click="card.action.method"
        >
          <v-row justify="center" class="ma-0 mb-1">
            <Logo :product="card.icon" height="48" color="primary" noMargins />
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-card-title
              v-text="card.title"
              class="pa-0 font-weight-medium"
              style="color: var(--v-primary-base)"
            />
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-card-subtitle
              v-text="card.subtitle"
              class="px-4 pb-0 text-center text--secondary"
              style="font-size: 0.99rem; min-height: 84px"
            />
          </v-row>
          <v-row justify="center" class="ma-0 mt-5">
            <v-card-actions class="py-0">
              <v-btn
                :key="card.action.text"
                color="accent"
                v-text="card.action.text"
                width="200"
                depressed
              />
            </v-card-actions>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col>
        <v-list-item>
          <v-list-item-icon class="my-1">
            <Logo
              :product="'frequently_asked_questions'"
              height="36"
              color="primary"
              noMargins
            />
          </v-list-item-icon>
          <v-list-item-title>
            <v-card-title
              v-text="'Perguntas frequentes'"
              class="pa-0 font-weight-medium"
              style="color: var(--v-primary-base)"
            />
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-row class="mt-2 mb-1">
          <v-col
            v-for="(group, i) in faq_groups"
            :key="`col-${i}`"
            v-show="group.faqs.length"
          >
            <v-card
              style="margin: auto"
              min-width="275"
              max-width="350"
              :class="`pb-3 card`"
              outlined
              hover
            >
              <div class="d-flex justify-center align-center grey lighten-5">
                <div class="d-flex align-start my-2">
                  <span>
                    <Logo
                      :product="group.product"
                      height="32"
                      noMargins
                      class="mr-5"
                    />
                  </span>
                  <b
                    class="py-1"
                    :style="`font-size: 18px; color: var(--v-${group.color}-base)`"
                    >{{ group.text }}</b
                  >
                </div>
              </div>
              <v-divider />
              <v-list>
                <v-list-item
                  v-for="(item, j) in group.faqs"
                  :key="`col-${i}-item-${j}`"
                  link
                  @click="openArticle(item)"
                >
                  <v-list-item-icon
                    :class="`grey lighten-4 rounded-circle pa-2 mr-4 my-2`"
                  >
                    <v-icon :color="group.color" v-text="'mdi-file-document'" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <p class="py-auto my-auto text-body-2">
                      {{ item.text }}
                    </p>
                  </v-list-item-content>
                  <v-list-item-action v-if="item.new">
                    <v-chip v-if="!seenHowToApplySign" small color="success"
                      >Novo</v-chip
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <v-divider />

        <v-card-actions class="px-0">
          <v-btn
            block
            text
            large
            color="accent"
            class="font-weight-medium"
            @click="openAllArticles"
          >
            Ver todos os artigos de ajuda
            <v-icon dense right v-text="'mdi-open-in-new'" />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0">
      <v-col>
        <v-alert tile v-if="hasConectaSupport" color="blue lighten-5 py-0">
          <v-list-item class="px-2">
            <v-list-item-icon class="mr-6" style="width: 45px">
              <Logo product="conecta_support" height="35" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="'Não encontrou o que procurava?'" />
              <v-list-item-subtitle class="text-wrap mt-1">
                Entre em contato com a nossa equipe de suporte,
                <v-btn
                  text
                  x-small
                  class="text-subtitle-2 text-none pa-0 py-1 ma-0"
                  color="blue darken-2"
                  @click="couldNotFind"
                >
                  clicando aqui </v-btn
                >.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  redirectToTheCommercialManagerGoogleCalendar,
  openInNewTab,
} from "@/helpers/services/utils";
import { faq_groups } from "@/helpers/variables/faqQuestions";

export default {
  name: "ContactUs",
  data: () => ({
    showHelpCard: false,
    faq_groups,
  }),
  computed: {
    ...mapGetters([
      "plansInTrialToShowSchedule",
      "hasConectaSupport",
      "company",
      "currentUser",
      "seenHowToApplySign",
      "isSmallClient",
    ]),
    cards() {
      return [
        {
          key: "schedule",
          icon: "video_plus",
          title: "Agende uma conversa",
          subtitle:
            "Converse com nossos especialistas e tire todas as suas dúvidas.",
          action: {
            text: "Agendar",
            method: this.scheduleCall,
          },
          show: this.plansInTrialToShowSchedule && !this.isSmallClient,
        },
        {
          key: "support",
          icon: "conecta_support",
          title: "Suporte via chat",
          subtitle: "Estamos aqui para ajudá-lo com o que precisar.",
          action: {
            text: "Chat",
            method: this.callSupport,
            class: "open_chat",
          },
          show: this.hasConectaSupport,
        },
        {
          key: "commercial",
          icon: "contact_us",
          title: "Chat ao vivo",
          subtitle: "Fale diretamente com nossos atendentes.",
          action: {
            text: "Falar",
            method: this.callCommercial,
          },
          // show: this.plansInTrialToShowSchedule,
          // // Aparecer somente quando tivermos um chat para o comercial
          show: false,
        },
        {
          key: "overview",
          icon: "compass",
          title: "Overview",
          subtitle: "Uma visão geral das funcionalidades do Conecta Suite.",
          action: {
            text: "Abrir",
            method: this.openTutorial,
          },
          show: true,
        },
        {
          key: "feedback",
          icon: "message_alert_outline",
          title: "Feedback",
          subtitle: "Envie sua opinião diretamente aos nosso desenvolvedores.",
          action: {
            text: "Enviar",
            method: this.sendFeedback,
          },
          show: true,
        },
      ];
    },
    breakpoint() {
      return this.$vuetify.breakpoint.width < 900;
    },
  },
  methods: {
    ...mapMutations(["setSeen", "feedbackButtonClick"]),
    ...mapActions(["verifyHubspotToken"]),
    openInNewTab,

    logEvent(event, clicked_where) {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      logEvent(analytics, event, {
        main_domain,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });
    },
    scheduleCall() {
      this.logEvent("contact_us_schedule_call", "opened from contact us page");
      const clicked_from = "Fale conosco (suporte)";
      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },
    callSupport() {
      this.logEvent(
        "contact_us_call_support",
        "opened for support from contact us page"
      );
      this.verifyHubspotToken();
    },
    couldNotFind() {
      this.logEvent(
        "contact_us_could_not_find",
        "opened for support from contact us page"
      );
      this.verifyHubspotToken();
    },
    callCommercial() {
      this.logEvent(
        "contact_us_call_commercial",
        "opened for commercial from contact us page"
      );
      this.callSupport();
    },
    sendFeedback() {
      this.logEvent("contact_us_send_feedback", "opened from contact us page");
      this.feedbackButtonClick();
    },
    googleSearch() {
      this.logEvent("contact_us_google_search", "opened from contact us page");
    },
    openTutorial() {
      this.logEvent("contact_us_open_tutorial", "opened from contact us page");
      this.openInNewTab("http://bit.ly/olaconectasuite");
    },
    openAllArticles() {
      this.logEvent(
        "contact_us_open_all_articles",
        "opened from contact us page"
      );
      this.openInNewTab(
        "https://conectasuite.com/perguntas-frequentes-conecta-suite/"
      );
    },
    openArticle(item) {
      this.logEvent("contact_us_open_article", "opened from contact us page");
      this.setSeen(item);
      this.openInNewTab(item.href);
    },
    loadGoogleSearch() {
      const script = document.createElement("script");
      script.src = "https://cse.google.com/cse.js?cx=9239b16838fa040bf";
      this.$refs.search.appendChild(script);
    },
  },
  mounted() {
    this.loadGoogleSearch();
  },
};
</script>
